import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import About from "../components/about.js"
import Books from "../components/books.js"
import Articles from "../components/articles.js"
import Courses from "../components/courses.js"
import { Helmet } from "react-helmet"

export default ({ data }) => {
  const theFooter = data.allWordpressPage.edges.filter(({ node }) => node.slug === "contact")[0].node
  const theHome = data.allWordpressPage.edges.filter(({ node }) => node.slug === "sarah-kovner")[0].node

  const theArticles = data.allWordpressPage.edges.filter(({ node }) => node.slug === "articles")[0].node.acf.articles.slice(0, 5)
  const theCourses = data.allWordpressPage.edges.filter(({ node }) => node.slug === "teaching")[0].node.acf.courses.slice(0, 5)


  const headerContent = <About value={theHome} />


  return (
    <Layout footer={theFooter} headerImageData={theHome.acf.background.localFile.childImageSharp.fluid} headerContent={headerContent}>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Sarah Kovner — Historian of Japan and the World</title>
        <link rel="canonical" href="https://sarahkovner.com" />
      </Helmet>

      <div class="bigstrip ">
        <div class="block">
          
        </div>
      </div>
      <section>
        <div class="block">
          <h2><a href="/books">Books</a></h2>
 
          {data.allWordpressPage.edges
            .filter(({ node }) => node.slug === "books")
            .map(({ node }, index) => (
              <Books value={node.acf.books} detail={false} />
            ))}
        </div>
      </section>
      <section class="stripe">
        <div class="block">
          <h2><a href="/articles">Publications</a></h2>


          <Articles value={theArticles} />
          <Link class="more" to="/articles">More publications...</Link>
        </div>
      </section>
     
    </Layout>
  )
}

export const query = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          content
          slug
          title
          acf {
            image {
              
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700, toFormat:PNG) {
                    aspectRatio
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }


            background {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700, duotone: {
                    highlight: "#263e5a",
                    shadow: "#222222"
                  }) {
                    aspectRatio
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            
            articles {
              title
              publication
              year
              citation
              link

            }
            book_chapters {
              title
              publication
              year
              citation
              link

            }
            translations {
              title
              publication
              year
              citation
              link

            }
            events {
              title
              date
              location
              link
              description
            }
            reviews {
              title
              publication
              year
              citation
              link

            }
            courses {
              course_name
              course_type
              school
              dates
              syllabus
            }
            books {
              title
              description
              short_description
              publisher
              year
              cover {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700, quality:90) {
                      aspectRatio
                      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
