import React from "react"
import Img from "gatsby-image"

const About = ({ value }) => {
  const fluid = value.acf.image.localFile.childImageSharp.fluid


  return (
    <div class="about">
      <div class="block">
        <div class="headshot">
          <div class="inner">
            <Img
              fluid={fluid}
              alt={value.title}
              fadeIn={true}
              backgroundColor={true}
            />
          </div>
        </div>
        <div class="bio">
          <div dangerouslySetInnerHTML={{ __html: value.content }} />
        </div>
      </div>
    </div>
  )
}

export default About
